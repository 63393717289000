@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf") format("embedded-opentype"),
    url("../fonts/Poppins-Regular.ttf") format("ttf"),
    url("../fonts/Poppins-Regular.ttf") format("truetype"),
    url("../fonts/Poppins-Regular.ttf") format("svg");
  size-adjust: 100%;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../fonts/Poppins-Bold.ttf") format("embedded-opentype"),
    url("../fonts/Poppins-Bold.ttf") format("ttf"),
    url("../fonts/Poppins-Bold.ttf") format("truetype"),
    url("../fonts/Poppins-Bold.ttf") format("svg");
  size-adjust: 100%;
}

body {
  margin: 0;
  line-height: 1.9 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
