.hover-btn:hover {
  background-color: #e79907 !important;
  transition: 0.2s;
}

.input::placeholder {
  color: "#fff" !important;
}

.text {
  background: linear-gradient(90deg, #db811e, #efd733);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
